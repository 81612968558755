/**
 * `404` typically displays a 404 screen for paths that don't match one of the
 * pages built by Gatsby. However, we also use this component to render previews
 * of repeatable content type documents that have never been published. Unpublished
 * documents aren't yet processed by Gatsby so they get routed to this 404 template whenever
 * a content admin tries to preview them. To resolve this, we're checking to see
 * if a preview query param exists on the url, and if so load the appropriate template.
 */

import React, { useEffect, useState } from "react";
import qs from "query-string";
import loadable from "@loadable/component";
import { useAppState } from "@state";
import useErrorData from "@staticQueries/Error404Query";
// import { use404PageData } from "@staticQueries";
import { DefaultPageContainer } from "@layout";
import { Container, Text } from "@atoms";

const Page = loadable(() => import("@dynamicQueries/PagesDefaultQuery"));
const Resource = loadable(() => import("@dynamicQueries/ResourcesQuery"));
const ImageCopy = loadable(() => import("@organisms/ImageCopy"));

const NotFoundPage = ({ pageContext, location, ...rest }) => {
  // eslint-disable-next-line no-unused-vars
  const [loaded, setLoaded] = useState(false);
  const [{ layout }, dispatch] = useAppState();

  useEffect(() => {
    dispatch({ type: "setThemeDark" });
  }, []);

  const { error } = useErrorData();

  useEffect(() => {
    // set preview mode if preview data is detected
    const {
      "x-craft-live-preview": xCraftLivePreview,
      "x-craft-preview": xCraftPreview,
    } = qs.parse(location.search);
    if (xCraftLivePreview || xCraftPreview) {
      dispatch({ type: "setPreviewMode", previewMode: "loading" });
    } else {
      setLoaded(true);
    }
  }, []);

  if (layout.previewMode) {
    // Use the appropriate page query based on the url
    // Note we need to manually set a `pageContext` prop and supply
    // it with a slug which we're grabbing from the last segment of the url.
    // This is used in the preview query to fetch the appropriate entry
    const split = location.pathname.split("/");
    const firstSegment = split[1];
    const lastSegment = split[split.length - 1];
    const props = { ...rest, location, pageContext: { slug: lastSegment } };

    // set up queries here for preview
    switch (firstSegment) {
      case "resources":
        return <Resource {...props} />;
      default:
        return <Page {...props} />;
    }
  }

  if (process.env.GATSBY_IN_DEV) {
    return (
      <DefaultPageContainer>
        <div className="flex flex-grow flex-col items-center justify-center text-center">
          <Container className="flex flex-col items-center justify-center">
            <Text variant="h3" className="mb-4">
              The site is still in progress.
            </Text>
            <Text variant="lg">
              Don&apos;t worry. Chances are the page you are looking for is not
              done yet.
            </Text>
          </Container>
        </div>
      </DefaultPageContainer>
    );
  }

  return (
    <DefaultPageContainer className="justify-center bg-midnight">
      <ImageCopy
        back
        bgColor="midnight"
        color="white"
        order={0}
        reverse
        showRibbons
        variant="RibbonsThree"
        {...error}
      />
    </DefaultPageContainer>
  );
};

export default NotFoundPage;
